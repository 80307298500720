import React, { createContext, useContext, useEffect, ReactNode, useCallback } from 'react';

// Typdefinition für den Broadcast-Context
interface BroadCastContextType {
  notifyLogout: () => void;
  notifyLogin: () => void;
}

// BroadcastChannel global initialisieren
const channel = new BroadcastChannel('broadcast-channel');

// Context erstellen
const BroadCastContext = createContext<BroadCastContextType | undefined>(undefined);

interface BroadCastProviderProps {
  children: ReactNode;
}

export const BroadCastProvider: React.FC<BroadCastProviderProps> = ({ children }) => {
  // Funktion, die eine Logout-Nachricht sendet

  const notifyLogout = useCallback(() => {
    channel.postMessage({ type: 'LOGOUT' });
  }, []);

  const notifyLogin = useCallback(() => {
    channel.postMessage({ type: 'LOGIN' });
  }, []);

  // Nachrichten aus dem BroadcastChannel empfangen
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (['LOGOUT', 'LOGIN'].includes(event.data.type)) {
        window.location.reload();
      }
    };

    channel.onmessage = handleMessage;
  }, []);

  // Kontextwert bereitstellen
  return (
    <BroadCastContext.Provider value={{ notifyLogout, notifyLogin }}>
      {children}
    </BroadCastContext.Provider>
  );
};

// Custom Hook für den Zugriff auf den Broadcast-Kontext
export const useBroadCast = (): BroadCastContextType => {
  const context = useContext(BroadCastContext);
  if (!context) {
    throw new Error('Brotkarsten muss innerhalb eines BroadCastProviders verwendet werden.');
  }
  return context;
};
