console.warn = () => {};
console.error = () => {};
import { useEffect, useRef, useState } from 'react';
import { useModel, useParams } from '@umijs/max';
import { Spin, message } from 'antd';
import { FileViewer } from '@digital-a-team/platform-file-viewer';
import FileHandler from '@/utils/s3';
import { Base64 } from 'js-base64';
import 'video-react/dist/video-react.css';
import '../project/campaign/CountDown.css';
import './campaign/CountDown.css';
import { ObjectCannedAcl, Tag_File } from '@/generated/graphql';
import { VBDocument } from '@/services/vertriebsbutler/file';

interface FileWithUrl extends File {
  url?: string;
}

const ProjectRecorder = () => {
  const params = useParams<{
    contact_id: string;
    project_id: string;
    files: string;
    folder?: string;
  }>();
  const [files, setFiles] = useState<Array<FileWithUrl>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { initialState } = useModel('@@initialState');
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
  const fileName = `Video-${formattedDate}`;
  const parts = useRef<Array<{ ETag: string; PartNumber: number }>>([]);
  const keyRef = useRef<string>('');
  const uploadIdRef = useRef<string>('');
  const [fh, setFh] = useState<FileHandler>();
  const [s3Folder, setS3Folder] = useState<string>();

  useEffect(() => {
    const init = async () => {
      setFh(await FileHandler.init());
    };
    init();
    setS3Folder(`contact/${params.contact_id}/project/${params.project_id}`);
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      if (!params.files) {
        setLoading(false);
        return;
      }
      const fileIds = JSON.parse(Base64.decode(params.files));
      const fh = await FileHandler.init();
      const { data } = await VBDocument.filesByIds(fileIds);

      const filesArray = await Promise.all(
        data.files
          .filter((file) => file.s3key && file.name?.indexOf('webm') === -1)
          .map(async (file) => {
            const f = await fh.download(
              file.name || 'Datei',
              file.s3key as string,
              file.mime || '',
            );
            return f ? f.data : null;
          }),
      );

      const validFiles = filesArray.filter((file) => file) as unknown as Array<File>;
      if (!validFiles) {
        console.error('No data found');
        return;
      }
      if (validFiles.length === 0) {
        console.error('No files found');
        return;
      }
      setFiles(validFiles);
      setLoading(false);
    };

    fetchFiles().catch(() => {
      message.error('Fehler beim Laden der Dateien');
      setLoading(false);
    });
  }, [params.files]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!files || !files.length) return <Spin />;

  const onClose = () => {
    history.back();
  };

  return (
    <FileViewer
      files={files}
      defaultFileName={fileName}
      onStartMultipartUpload={async () => {
        const filename = `${fileName}.webm`;
        const s3Key = `${s3Folder}/${filename}`;
        const res = await fh?.initMultipart({
          s3Key,
          name: filename,
          contentType: 'video/webm',
          acl: ObjectCannedAcl.PublicRead,
          preInsert: false,
          awsTags: [
            {
              name: 'contact_id',
              value: params.contact_id?.toString() || '',
            },
            {
              name: 'project_id',
              value: params.project_id?.toString() || '',
            },
            { name: 'client_id', value: initialState?.client?.client_id || '' },
            { name: 'user_pool_id', value: initialState?.client?.user_pool_id || '' },
          ],
        });
        if (res?.data?.res.multipartUploadId) {
          keyRef.current = s3Key;
          uploadIdRef.current = res.data.res.multipartUploadId;
        }
      }}
      onUploadPart={async (part: number, body: Blob) => {
        const res = await fh?.uploadMultipart(keyRef.current, part, uploadIdRef.current, body);
        if (!res) throw new Error('Upload failed');
        parts.current.push(res);
      }}
      onCompleteMultipartUpload={async (
        filename: string,
        _: boolean,
        size: number,
        duration: number,
        thumbnail: Blob,
      ) => {
        const { contact_id, project_id } = params;
        if (!contact_id || !project_id) return false;
        if (!filename.endsWith('.webm')) {
          filename = `${filename}.webm`;
        }
        const awsTags = [
          {
            name: 'contact_id',
            value: contact_id?.toString() || '',
          },
          {
            name: 'project_id',
            value: project_id?.toString() || '',
          },
          { name: 'client_id', value: initialState?.client?.client_id || '' },
          { name: 'user_pool_id', value: initialState?.client?.user_pool_id || '' },
        ];
        const thumbName = filename.replace('.webm', '_thumb.jpg');
        const thumbKey = `${s3Folder}/${thumbName}`;
        fh?.upload({
          acl: ObjectCannedAcl.PublicRead,
          awsTags,
          Body: new File([thumbnail], `${thumbName}`),
          contentType: 'image/jpeg',
          preInsert: false,
          s3Key: thumbKey,
        });

        await fh?.completeMultipartUpload(
          {
            s3key: keyRef.current,
            backgroundProcess: {
              fixVideo: true,
            },
            multipart: {
              parts: parts.current.sort((a, b) => a.PartNumber - b.PartNumber),
              uploadId: uploadIdRef.current,
            },
            contentType: 'video/webm',
            filename,
          },
          {
            s3key: keyRef.current,
            name: filename,
            thumbnail_s3key: thumbKey,
            mime: 'video/webm',
            size,
            tags: [Tag_File.OfferVideo],

            url: fh?.getUrl(keyRef.current),
            client_id: initialState?.client?.client_id || '',
            contact_id: contact_id,
            project_id: project_id,
          },
        );

        return Promise.resolve(true);
      }}
      onAbortMultipartUpload={async () => {
        parts.current = [];
        await fh?.abortMultipartUpload(keyRef.current, uploadIdRef.current);
      }}
      onClose={onClose}
    />
  );
};

export default ProjectRecorder;
