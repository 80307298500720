import { gql } from '@apollo/client';
import { query } from '.';
import { MainConfig } from '@/generated/graphql';

const CFG_GLOBAL = gql`
  query GetMainConfig($where: MainConfigInput!) {
    getMainConfig(where: $where) {
      client_id
      bucket
      domain
      identity_pool_id
      logo
      styles
      user_pool_id
      disabled
      access {
        avatar
      }
    }
  }
`;

export interface IAppStyles {
  antd: {
    token: { [key: string]: string };
    components: { [key: string]: string };
  };
  surveyjs: { [key: string]: string };
}

type ApolloResponse = {
  getMainConfig: MainConfig;
};
const getConfig = async (): Promise<MainConfig> => {
  try {
    const { data, errors } = await query<ApolloResponse>({
      query: CFG_GLOBAL,
      variables: {
        where: {
          domain: document.location.origin,
        },
      },
    });

    if (data && data.getMainConfig) {
      window.localStorage.setItem('user_pool_id', data.getMainConfig.user_pool_id);
      window.localStorage.setItem('client_id', data.getMainConfig.client_id);
      window.localStorage.setItem('identity_pool_id', data.getMainConfig.identity_pool_id);
      window.localStorage.setItem('bucket', data.getMainConfig.bucket);
      return data.getMainConfig;
    } else {
      throw new Error('No data received from the server.');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const configApi = {
  getConfig,
};
