import { gql } from '@apollo/client';
import { mutate, query } from '.';
import { VBBaseClass } from './generic';
import {
  AbortMultipartUploadInput,
  FileCreateInput,
  FileUploadCompleteInput,
  FileUploadInitInput,
  FileUploadInitOutput,
  FileUploadPartInput,
  GetFileInput,
} from '../../generated/graphql';
import { File } from '@/generated/graphql';
import { v4 } from 'uuid';

export type ListDocumentFilter = { project_id?: string; folder?: string; campaign_id?: string };

const DEFAULT_FIELDS = `
  id
  created
  mime
  name
  s3key
  size
  tags
  url
  folder
  thumbnail_s3key
  status
  status_text
`;

export class VBDocument {
  private static baseClass = new VBBaseClass<File>('File', DEFAULT_FIELDS);

  static createOne(data: FileCreateInput) {
    const qry = gql`
      mutation Mutation($data: FileCreateInput!) {
        File: createOneFile(data: $data) {
          id
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          __typename
        }
      }
    `;
    return mutate<{ File: File }>({
      mutation: qry,
      variables: {
        data,
      },
    });
  }

  static updateOne(data: File) {
    return this.baseClass.updateOne(data);
  }

  static async upsertOne(data: Partial<File>) {
    if (!data.client_id || !data.s3key) {
      throw new Error('client_id and s3key are required');
    }
    const file = await this.selectByClientIdAndS3Key(data.client_id, data.s3key);
    const qry = gql`
      mutation UpsertOneFile(
        $where: FileWhereUniqueInput!
        $create: FileCreateInput!
        $update: FileUpdateInput!
      ) {
        upsertOneFile(where: $where, create: $create, update: $update) {
          id
        }
      }
    `;
    return mutate<{ upsertOneFile: { id: string } }>({
      mutation: qry,
      variables: {
        where: {
          id: file?.id || v4(),
        },
        create: {
          folder: data.folder,
          s3key: data.s3key,
          name: data.name,
          thumbnail_s3key: data.thumbnail_s3key,
          mime: data.mime,
          size: data.size,
          tags: {
            set: data.tags,
          },
          url: data.url,
          client: {
            connect: {
              client_id: data.client_id,
            },
          },
          ...(data.campaign_id && {
            campaign: {
              connect: {
                id: data.campaign_id,
              },
            },
          }),
          ...(data.contact_id && {
            contact: {
              connect: {
                id: data.contact_id,
              },
            },
          }),
          ...(data.project_id && {
            project: {
              connect: {
                id: data.project_id,
              },
            },
          }),
        },
        update: {
          ...(data.folder && { folder: { set: data.folder } }),
          ...(data.s3key && { s3key: { set: data.s3key } }),
          name: { set: data.name },
          ...(data.thumbnail_s3key ? { thumbnail_s3key: { set: data.thumbnail_s3key } } : {}),
          mime: { set: data.mime },
          size: { set: data.size },
          ...(data.tags && { tags: { set: data.tags } }),
          ...(data.url ? { url: { set: data.url } } : {}),
        },
      },
    });
  }

  static deleteOne(id: string) {
    return this.baseClass.deleteOne(id);
  }

  static selectOne(id: string) {
    return this.baseClass.selectOne(id);
  }

  static selectMany(filter: ListDocumentFilter) {
    const QUERY_LIST_DOCUMENTS = gql`
      query Files(
        $folderWhere: FileWhereInput
        $filesWhere: FileWhereInput
        $orderBy: [FileOrderByWithRelationInput!]
        $distinct: [FileScalarFieldEnum!]
      ) {
        folder: files(where: $folderWhere, orderBy: $orderBy, distinct: $distinct) {
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          thumbnail_s3key
          campaign_id
        }
        files: files(where: $filesWhere, orderBy: $orderBy) {
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          thumbnail_s3key
          campaign_id
          id
        }
      }
    `;

    if (!filter.project_id) new Error('project_id is required');
    const filterProjectId = filter.project_id
      ? {
          project_id: {
            equals: filter.project_id,
          },
        }
      : {};
    const filterFolderNameA = filter.folder
      ? {
          folder: {
            startsWith: filter.folder,
          },
        }
      : {
          folder: {
            not: null,
          },
        };
    const filterFolderNameB = filter.folder
      ? { folder: { equals: filter.folder } }
      : {
          folder: {
            equals: null,
          },
        };

    return query<{
      folder: Array<File>;
      files: Array<File>;
    }>({
      query: QUERY_LIST_DOCUMENTS,
      variables: {
        folderWhere: {
          deleted: {
            equals: null,
          },
          AND: [filterProjectId, filterFolderNameA],
        },
        filesWhere: {
          deleted: {
            equals: null,
          },

          AND: [filterProjectId, filterFolderNameB],
        },
        distinct: 'folder',
        orderBy: [
          {
            name: {
              sort: 'asc',
            },
          },
        ],
      },
    });
  }

  static async selectByClientIdAndS3Key(client_id: string, s3Key: string) {
    const qry = gql`
      query Files($where: FileWhereInput) {
        files(where: $where) {
          url
          s3key
          id
          name
          folder
          mime
        }
      }
    `;
    const res = await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          client_id: {
            equals: client_id,
          },
          s3key: {
            equals: s3Key,
          },
          deleted: {
            equals: null,
          },
        },
      },
    });
    return res.data?.files && res.data.files.length > 0 ? res.data.files[0] : null;
  }

  static async selectFilesInFolder(folder: string, project_id: string) {
    const qry = gql`
      query Files($where: FileWhereInput) {
        files(where: $where) {
          url
          s3key
          id
          name
          folder
          mime
        }
      }
    `;
    const res = await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          folder: {
            startsWith: folder,
          },
          AND: [
            {
              project_id: {
                equals: project_id,
              },
            },
            {
              deleted: {
                equals: null,
              },
            },
          ],
        },
      },
    });
    return res.data?.files;
  }
  static async getOfferFiles(campaign_id: string) {
    try {
      const qry = gql`
        query Files($where: FileWhereInput) {
          files(where: $where) {
            url
            s3key
            id
            name
            folder
            tags
            mime
          }
        }
      `;
      const result = await query<{ files: File[] }>({
        query: qry,
        variables: {
          where: {
            campaign_id: { equals: campaign_id },
            AND: [
              {
                deleted: {
                  equals: null,
                },
                tags: {
                  hasSome: ['offer', 'offer_file', 'offer_video'],
                },
              },
            ],
          },
        },
      });
      return result.data?.files;
    } catch (error) {
      console.error('Error fetching files by campaign ID:', error);
      throw error;
    }
  }

  static async trackDownload(fileId: string) {
    try {
      await mutate({
        mutation: gql`
          mutation TrackDownload($fileId: String!) {
            trackDownload(fileId: $fileId) {
              id
            }
          }
        `,
        variables: { fileId },
      });
      console.log(`Download tracked for fileId: ${fileId}`);
    } catch (error) {
      console.error('Error tracking download:', error);
      throw error;
    }
  }

  static async selectInquiry(contact_id: string) {
    const qry = gql`
      query Files($where: FileWhereInput, $orderBy: [FileOrderByWithRelationInput!]) {
        files(where: $where, orderBy: $orderBy) {
          url
          s3key
          id
          name
          tags
          mime
          sub_files {
            url
            id
            s3key
            name
            tags
            mime
          }
        }
      }
    `;
    return await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          AND: [
            { contact_id: { equals: contact_id } },
            { project_id: { equals: null } },
            { main_file_id: null },
            {
              deleted: {
                equals: null,
              },
              tags: {
                hasSome: ['inquiry'],
              },
            },
          ],
        },
        orderBy: [
          {
            created: 'desc',
          },
        ],
      },
    });
  }

  static async linkToProject(file_id: string, project_id: string) {
    const qry = gql`
      mutation LinkFilesToProject($input: LinkFilesToProjectInput!) {
        linkFilesToProject(input: $input)
      }
    `;
    return await mutate<Boolean>({
      mutation: qry,
      variables: {
        input: {
          project_id,
          file_id,
        },
      },
    });
  }

  static async deleteInquiry(id: string) {
    const qry = gql`
      mutation UpdateManyFile($data: FileUpdateManyMutationInput!, $where: FileWhereInput) {
        updateManyFile(data: $data, where: $where) {
          count
        }
      }
    `;
    return await mutate<Boolean>({
      mutation: qry,
      variables: {
        data: {
          deleted: {
            set: new Date().toISOString(),
          },
        },
        where: {
          OR: [{ id: { equals: id } }, { main_file_id: { equals: id } }],
        },
      },
    });
  }

  static async abortMultipartUpload(input: AbortMultipartUploadInput) {
    const query = gql`
      mutation FileUploadAbortMultipartUpload($input: AbortMultipartUploadInput!) {
        fileUploadAbortMultipartUpload(input: $input)
      }
    `;
    await mutate({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async selectManyAvatarFiles(avatar_id: string) {
    const qry = gql`
      query Files($where: FileWhereInput) {
        files(where: $where) {
          url
          s3key
          id
          name
          folder
          tags
          mime
          created
          updated
          status
          status_text
        }
      }
    `;
    return await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          AND: [{ avatar_id: { equals: avatar_id } }, { deleted: { equals: null } }],
        },
      },
    });
  }

  static async uploadInit(input: FileUploadInitInput) {
    const query = gql`
      mutation FileUploadInit($input: FileUploadInitInput!) {
        res: fileUploadInit(input: $input) {
          id
          multipartUploadId
          url
        }
      }
    `;

    return await mutate<{ res: FileUploadInitOutput }>({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async signedMultipartUrl(input: FileUploadPartInput) {
    const query = gql`
      mutation RequestSignedUrlMultipart($input: FileUploadPartInput!) {
        res: requestSignedUrlMultipart(input: $input)
      }
    `;
    return await mutate<{ res: string }>({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async completeUpload(input: FileUploadCompleteInput) {
    const query = gql`
      mutation FileUploadComplete($input: FileUploadCompleteInput!) {
        fileUploadComplete(input: $input)
      }
    `;

    return await mutate<boolean>({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async presignedUrlGet(input: GetFileInput) {
    const query = gql`
      mutation SignedUrlFileGet($input: GetFileInput!) {
        url: signedUrlFileGet(input: $input)
      }
    `;
    return await mutate<{ url: string }>({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async filesByIds(ids: Array<string>) {
    const qry = gql`
      query Files($where: FileWhereInput) {
        files(where: $where) {
          mime
          name
          s3key
          thumbnail_s3key
          url
        }
      }
    `;
    return await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
    });
  }

  static CHANGE_SUBSCRIPTION = gql`
    subscription FileChangeSubscription($user_id: String!) {
      fileChangeSubscription(user_id: $user_id) {
        file {
          id
          client_id
          contact_id
          mime
          size
          created
          updated
          deleted
          campaign_id
          name
          url
          s3key
          tags
          main_file_id
          folder
          project_id
          thumbnail_s3key
          sync_date
          sync_id
          avatar_id
          status
          status_text
          md5
          relevance
          group_name
        }
        state
      }
    }
  `;

  static STATUS_SUBSCRIPTION = gql`
    subscription FileStatusSubscription($user_id: String!) {
      fileStatusSubscription(user_id: $user_id) {
        id
        status
        status_text
      }
    }
  `;
}
